<template>
    <Button
        label="Atualizar"
        icon="pi pi-pencil"
        @click="toAtualizar()"
        v-if="$temAcessoView('PAG-CONTAS-03')"
    />
</template>

<script>
export default {
    props: {
        conta: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$router.push({
                name: `Pagamentos_Contas_Atualizar`,
                params: {
                    id: this.conta.contaId,
                },
            });
        },
    },
};
</script>
