<template>
    <painel titulo="Conta" icone="pi pi-dollar" :refreshFunction="obterConta">
        <div class="mb-4">
            <detalhe titulo="Id" :size="sizeForm">{{ conta?.contaId }}</detalhe>
            <detalhe titulo="Conta" :size="sizeForm">{{ conta?.descricao }}</detalhe>
            <detalhe titulo="Banco" :size="sizeForm">{{ conta?.banco.nomeCompleto }}</detalhe>
            <detalhe titulo="Banco ISPB" :size="sizeForm">{{ conta?.banco.ispb }}</detalhe>
            <detalhe titulo="Banco Código" :size="sizeForm">{{ conta?.banco.codigo }}</detalhe>
            <detalhe titulo="Objeto de Integração" :size="sizeForm">
                <vue-json-pretty
                    :data="JSON.parse(conta?.objetoIntegracao)"
                    :showDoubleQuotes="true"
                    v-if="conta && conta.objetoIntegracao && isJsonString(conta.objetoIntegracao)"
                ></vue-json-pretty>
                <span v-if="conta && conta.objetoIntegracao && !isJsonString(conta.objetoIntegracao)">{{ conta?.objetoIntegracao }}</span>
            </detalhe>
        </div>
        <btn-atualizar :conta="conta"></btn-atualizar>
        <btn-inserir class="ml-2"></btn-inserir>
        <btn-voltar
            :route="{
                name: `Pagamentos_Contas`,
            }"
        ></btn-voltar>
    </painel>
</template>

<script>
import ContasService from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        VueJsonPretty,
    },

    data() {
        return {
            conta: null,
        };
    },

    computed: {
        sizeForm() {
            return '150';
        },
    },

    methods: {
        obterConta() {
            this.$store.dispatch('addRequest');
            ContasService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.conta = response.data;
                } else {
                    this.conta = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
    },

    mounted() {
        this.obterConta();
    },
};
</script>

<style>
span.vjs-value__string {
    word-break: break-word !important;
}
</style>
